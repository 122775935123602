<template>
  <div class="Message wrapper">
    <Header></Header>
    <el-main class="centerWidth" v-if="languageSelect==='zh'">
      <el-breadcrumb class="centerWidth" separator="/" replace>
        <el-breadcrumb-item :to="{ path: '/' }">油气知识</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/message' }"
        >消息中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/message' }"
        >系统消息</el-breadcrumb-item
        >
        <el-breadcrumb-item>消息详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="mainContent">
        <div class="title">
          {{ title }}
        </div>
        <div class="time">{{ time }}</div>
        <div class="content">
          <div class="html" v-html="content"></div>
        </div>
      </div>
      <AiPop @change="showDialog"></AiPop>
    </el-main>
    <el-main class="centerWidth" v-if="languageSelect==='en'">
      <el-breadcrumb class="centerWidth" separator="/" replace>
        <el-breadcrumb-item :to="{ path: '/' }">Oil and Gas knowledge</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/message' }"
        >Message center</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/message' }"
        >System message</el-breadcrumb-item
        >
        <el-breadcrumb-item>Message details</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="mainContent">
        <div class="title">
          {{ title }}
        </div>
        <div class="time">{{ time }}</div>
        <div class="content">
          <div class="html" v-html="content"></div>
        </div>
      </div>
      <AiPop @change="showDialog"></AiPop>
    </el-main>
    <Footer></Footer>
  </div>
</template>
<script>
import { noticeDetail } from "@/api/user";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AiPop from "@/components/AiPop.vue";
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "MessageDetail",
  components: { Header, Footer,
    AiPop },
  data() {
    return {
      id: "",
      title: "",
      time: "",
      content: ``,
      dialogVisible: false,
      languageSelect:''
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.noticeDetail();
    }
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气知识',
        text:'洞悉油气行业变化，体验AI大模型，就来油气知识！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    noticeDetail() {
      noticeDetail(this.id).then((res) => {
        this.title = res.data.title || "";
        this.time = res.data.createTime || "";
        this.content = res.data.content || "";
      });

    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.mainContent {
  background: #fff;
  padding: 0 28px;
  min-height: 426px;
  .title {
    font-size: 24px;
    color: #333333;
    font-weight: 500;
    text-align: center;
    padding-top: 33px;
    margin-bottom: 21px;
  }

  .time {
    font-size: 14px;
    color: #999999;
    text-align: center;
    margin-bottom: 22px;
  }

  .content {
    border-top: 1px solid #ebebeb;
    padding: 30px 140px;
  }
}
img,
video {
  max-width: 100%;
}
</style>

